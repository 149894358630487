import { forwardRef } from "react"

import { cn } from "@/lib/utils/classnames"
import { typographyVariants } from "@/components/ui/typography"

export const Page = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
	({ children, className, ...props }, ref) => (
		<div
			ref={ref}
			{...props}
			className={cn(
				"flex grow flex-col border border-border-weak bg-background shadow-md md:rounded-md",
				className,
			)}
		>
			{children}
		</div>
	),
)
Page.displayName = "Page"

export const PageHeader = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn("flex items-center justify-between p-3 pb-2", className)}
	>
		{children}
	</div>
))
PageHeader.displayName = "PageHeader"

export const PageTitle = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn(
			"text-2xl font-bold capitalize text-foreground-strong",
			className,
		)}
	>
		{children}
	</div>
))
PageTitle.displayName = "PageTitle"

export const PageInfo = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div ref={ref} {...props} className={cn("flex flex-col", className)}>
		{children}
	</div>
))
PageInfo.displayName = "PageInfo"

export const PageGroup = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn(typographyVariants({ variant: "overline" }), className)}
	>
		{children}
	</div>
))
PageGroup.displayName = "PageGroup"

export const PageActions = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div ref={ref} {...props} className={cn("flex shrink-0 gap-1", className)}>
		{children}
	</div>
))
PageActions.displayName = "PageActions"

export const PageBody = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn("size-full overflow-hidden", className)}
	>
		{children}
	</div>
))
PageBody.displayName = "PageBody"

export const PageContent = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn("flex size-full flex-col overflow-auto", className)}
	>
		{children}
	</div>
))
PageContent.displayName = "PageContent"
