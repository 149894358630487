import { Children, useCallback, useEffect, useRef, useState } from "react"
import type { HTMLAttributes, ReactNode } from "react"
import { tv } from "tailwind-variants"

import { cn } from "@/lib/utils/classnames"

import { focusRing } from "../utils"
import { Button } from "./button"

export interface ChipProps extends React.HTMLAttributes<HTMLDivElement> {
	variant?: "primary" | "outline" | "info" | "success" | "warning" | "error"
	fill?: boolean // New prop to control background fill
}

export const chipClasses = tv({
	base: [
		"inline-flex select-none items-center rounded-md border px-2 py-0.5 text-xs font-semibold transition-colors",
		focusRing,
	],
	variants: {
		variant: {
			primary:
				"border-transparent bg-primary text-primary-foreground shadow",
			outline: "bg-background text-foreground",
			info: "border-transparent bg-info text-info-foreground shadow",
			success:
				"border-transparent bg-success text-success-foreground shadow",
			warning:
				"border-transparent bg-warning text-warning-foreground shadow",
			error: "border-transparent bg-error text-error-foreground shadow",
		},
		fill: {
			true: "",
			false: {
				primary:
					"border-primary bg-transparent text-primary shadow-none",
				outline:
					"border-foreground bg-transparent text-foreground shadow-none",
				info: "border-info bg-transparent text-info shadow-none",
				success:
					"border-success bg-transparent text-success shadow-none",
				warning:
					"border-warning bg-transparent text-warning shadow-none",
				error: "border-error bg-transparent text-error shadow-none",
			},
		},
	},
	defaultVariants: {
		variant: "outline",
		fill: true, // Default to true
	},
	compoundVariants: [
		{
			fill: false,
			variant: "primary",
			className:
				"border-primary bg-transparent text-primary-text shadow-none",
		},
		{
			fill: false,
			variant: "success",
			className:
				"border-success bg-transparent text-success-text shadow-none",
		},
		{
			fill: false,
			variant: "warning",
			className:
				"border-warning bg-transparent text-warning-text shadow-none",
		},
		{
			fill: false,
			variant: "error",
			className:
				"border-error-border/30 bg-transparent text-error-text shadow-none",
		},
		{
			fill: false,
			variant: "outline",
			className:
				"border-foreground bg-transparent text-foreground shadow-none",
		},
	],
})

const Chip: React.FC<ChipProps> = ({
	className,
	variant,
	fill = true,
	...props
}) => {
	return (
		<div className={chipClasses({ variant, fill, className })} {...props} />
	)
}

interface ChipContainerProps extends HTMLAttributes<HTMLDivElement> {
	children: ReactNode
	visibleCount?: number
}

const ChipContainer = ({
	children,
	visibleCount,
	className,
}: ChipContainerProps) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const chipsRef = useRef<HTMLDivElement>(null)
	const [isExpanded, setIsExpanded] = useState(false)
	const [isOverflowing, setIsOverflowing] = useState(false)
	const childrenArray = Children.toArray(children)
	const truncateAt = visibleCount || childrenArray.length
	const truncatedCount =
		visibleCount && truncateAt ? childrenArray.length - truncateAt : null

	const checkForOverflow = useCallback(() => {
		const container = containerRef.current
		const chips = chipsRef.current

		if (!container || !chips) return

		// Use scrollWidth instead of clientWidth to detect actual content width
		const chipsWidth = chips.scrollWidth
		const containerWidth = container.clientWidth

		if (truncatedCount && truncatedCount < childrenArray.length) {
			setIsOverflowing(true)
		} else {
			// Add 1px buffer to prevent edge case flickering
			setIsOverflowing(chipsWidth > containerWidth + 1)
		}
	}, [childrenArray.length, truncatedCount])

	// Initial check after render
	useEffect(() => {
		// Double check after a small delay to ensure layout is complete
		const timeout = setTimeout(checkForOverflow, 100)
		return () => clearTimeout(timeout)
	}, [checkForOverflow])

	// Observe both container and chips for size changes
	useEffect(() => {
		const resizeObserver = new ResizeObserver(checkForOverflow)
		const container = containerRef.current
		const chips = chipsRef.current

		if (container) resizeObserver.observe(container)
		if (chips) resizeObserver.observe(chips)

		return () => {
			if (container) resizeObserver.unobserve(container)
			if (chips) resizeObserver.unobserve(chips)
		}
	}, [checkForOverflow])

	// Add window resize listener as fallback
	useEffect(() => {
		window.addEventListener("resize", checkForOverflow)
		return () => window.removeEventListener("resize", checkForOverflow)
	}, [checkForOverflow])

	// Check when children change or expanded state changes
	useEffect(() => {
		checkForOverflow()
	}, [isExpanded, childrenArray.length, checkForOverflow])

	return (
		<div className={cn("flex gap-1", className)}>
			<div ref={containerRef} className="relative overflow-hidden">
				<div
					ref={chipsRef}
					className={cn(
						"flex w-fit items-start gap-1 overflow-visible pt-1.5 [&>*]:shrink-0",
						isExpanded && "flex-wrap",
					)}
				>
					{isExpanded ? children : childrenArray.slice(0, truncateAt)}
				</div>
				{isOverflowing && !isExpanded && (
					<div className="absolute right-0 top-0 h-[30px] w-[10px] bg-[linear-gradient(90deg,transparent,hsla(var(--background)))]" />
				)}
			</div>
			{(isOverflowing || isExpanded) && (
				<Button
					onClick={() => setIsExpanded((prev) => !prev)}
					variant="ghost"
					size="sm"
					className="shrink-0 px-1 py-0 text-primary"
				>
					+ {truncatedCount || "more"}
				</Button>
			)}
		</div>
	)
}
export { Chip, ChipContainer }
